// NOTE Global Variables
@use "sass:map";

// Fonts $font-style
// $font-Regular: 'Roboto';
// $font-Thin: 'Roboto-Thin';
// $font-ExtraLight: 'Roboto-ExtraLight';
// $font-Light: 'Roboto-Light';
// $font-Medium: 'Roboto-Medium';
// $font-SemiBold: 'Roboto-SemiBold';
// $font-Bold: 'Roboto-Bold';
// $font-ExtraBold: 'Roboto-ExtraBold';
// $font-Heavy: 'Roboto-Heavy';

$font-8: 8px;
$font-10: 10px;
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-17: 17px;
$font-18: 18px;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;
$font-26: 26px;
$font-28: 28px;
$font-30: 30px;
$font-32: 32px;
$font-34: 34px;
$font-36: 36px;
$font-38: 38px;
$font-40: 40px;
$font-42: 42px;
$font-44: 44px;
$font-46: 46px;
$font-48: 48px;

// Colors -> $color-usage/color
$color-primary: #2bb14c;
$color-accent: #202a3e;

$color-aerial: #2600FA;
$color-ricoh: orange;

// Standard Colors
$color-black: #000;
$color-white: #fff;
$color-light-grey: #959799;
$color-dark-grey: #3e4654;
$color-dark-blue: $color-accent;
$color-dark-blue-purlple: #3a3952;

$color-title-dark: $color-dark-blue;
$color-label-light: $color-light-grey;
$color-label-dark: $color-dark-grey;
$color-input-placeholder-background-light: #f6f6f6;
$color-input-border-color: #e5e5e5;
$color-input-read-only: #dbdbdb;
$color-links: #6d6d6d;
$color-error: #ED1A1A;
$color-router-outlet-background: #f0f0f7;
$color-table-border: #ebebed;
$color-dadada: #dadada;
$color-nav-active-background: #303857;
$color-selected-pin: #ED1A1A;
$color-sidebar-seperator: #707070;
$color-scrollbar: #F5F5F5;
$color-upload-btn: #bec0c4;
$color-upload-background: #848992;
$color-modal-background: #000000c7;
$color-drawer-background: #000000cc;
$color-header-background: #E0E0E0;
$color-toolbar-shadow: #8690a3;
$color-border-bottom: #efeff1;

// Theme Colors
$sitepics-palette: (
  50: #e6f5e9,
  100: #c3e7c9,
  200: #9cd7a6,
  300: #73c982,
  400: #51bd68,
  500: $color-primary,
  600: #21a243,
  700: #139038,
  800: #007f2d,
  900: #006019,

  contrast: (
    50: rgba($color-black, 0.87),
    100: rgba($color-black, 0.87),
    200: rgba($color-black, 0.87),
    300: rgba($color-black, 0.87),
    400: rgba($color-black, 0.87),
    500: $color-white,
    600: $color-white,
    700: $color-white,
    800: $color-white,
    900: $color-white,
  ),
  // A50: rgba($color-black, 0.87),
  // A100: rgba($color-black, 0.87),
  // A200: rgba($color-black, 0.87),
  // A300: rgba($color-black, 0.87),
  // A400: rgba($color-black, 0.87),
  // A500: $color-white,
  // A600: $color-white,
  // A700: $color-white,
  // A800: $color-white,
  // A900: $color-white,,
);

$sitepics-palette-accent: (
  50: #e9edff,
  100: #c9d4e9,
  200: #adb7d0,
  300: #8e9bb8,
  400: #7885a5,
  500: #617193,
  600: #536382,
  700: #42506c,
  800: #333e56,
  900: $color-accent,

  contrast: (
    50: rgba($color-black, 0.87),
    100: rgba($color-black, 0.87),
    200: rgba($color-black, 0.87),
    300: rgba($color-black, 0.87),
    400: rgba($color-white, 0.87),
    500: $color-white,
    600: $color-white,
    700: $color-white,
    800: $color-white,
    900: $color-white,
  )
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

// $negative-spacers: if ($enable-negative-margins, negativify-map($spacers), null);

@import './variables.scss';
/* Custom Scrollbar */
@mixin scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
    background-color: $color-scrollbar;
    z-index: 10;
  }
  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
    background-color: $color-scrollbar;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 4px;
  }
}

@mixin modal-scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
    background-color: $color-scrollbar;
    z-index: 10;
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: $color-scrollbar;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 4px;
  }
}

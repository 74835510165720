@import 'variables.scss';

.mx-auto { display: block !important; width: auto !important;}
.d-flex { display: flex !important }
.d-none { display: none !important }
.flex-row { flex-direction: row }
.flex-column { flex-direction: column }
.justify-content-end { justify-content: end }
.justify-content-start { justify-content: start }
.justify-content-center { justify-content: center }
.justify-content-between { justify-content: space-between }

.m-0 { margin: 0 !important }
.m-1 { margin: ($spacer * .25) !important }
.m-2 { margin: ($spacer * .5) !important }
.m-3 { margin: ($spacer) !important }
.m-4 { margin: ($spacer * 1.5) !important }
.m-5 { margin: ($spacer * 2) !important }
.m-6 { margin: ($spacer * 3) !important }

.mt-0 { margin-top: 0 !important }
.mt-1 { margin-top: ($spacer * .25) !important }
.mt-2 { margin-top: ($spacer * .5) !important }
.mt-3 { margin-top: ($spacer) !important }
.mt-4 { margin-top: ($spacer * 1.5) !important }
.mt-5 { margin-top: ($spacer * 2) !important }
.mt-6 { margin-top: ($spacer * 3) !important }

.mb-0 { margin-bottom: 0 !important }
.mb-1 { margin-bottom: ($spacer * .25) !important }
.mb-2 { margin-bottom: ($spacer * .5) !important }
.mb-3 { margin-bottom: ($spacer) !important }
.mb-4 { margin-bottom: ($spacer * 1.5) !important }
.mb-5 { margin-bottom: ($spacer * 2) !important }
.mb-6 { margin-bottom: ($spacer * 3) !important }

.mr-0 { margin-right: 0 !important }
.mr-1 { margin-right: ($spacer * .25) !important }
.mr-2 { margin-right: ($spacer * .5) !important }
.mr-3 { margin-right: ($spacer) !important }
.mr-4 { margin-right: ($spacer * 1.5) !important }
.mr-5 { margin-right: ($spacer * 2) !important }
.mr-6 { margin-right: ($spacer * 3) !important }

.ml-0 { margin-left: 0 !important }
.ml-1 { margin-left: ($spacer * .25) !important }
.ml-2 { margin-left: ($spacer * .5) !important }
.ml-3 { margin-left: ($spacer) !important }
.ml-4 { margin-left: ($spacer * 1.5) !important }
.ml-5 { margin-left: ($spacer * 2) !important }
.ml-6 { margin-left: ($spacer * 3) !important }

.ml-15-px { margin-left: 15px }

.px-auto { display: block !important; width: auto !important; }

.p-0 { padding: 0 !important }
.p-1 { padding: ($spacer * .25) !important }
.p-2 { padding: ($spacer * .5) !important }
.p-3 { padding: ($spacer) !important }
.p-4 { padding: ($spacer * 1.5) !important }
.p-5 { padding: ($spacer * 2) !important }
.p-6 { padding: ($spacer * 3) !important }

.pt-0 { padding-top: 0 !important }
.pt-1 { padding-top: ($spacer * .25) !important }
.pt-2 { padding-top: ($spacer * .5) !important }
.pt-3 { padding-top: ($spacer) !important }
.pt-4 { padding-top: ($spacer * 1.5) !important }
.pt-5 { padding-top: ($spacer * 2) !important }
.pt-6 { padding-top: ($spacer * 3) !important }

.pb-0 { padding-bottom: 0 !important }
.pb-1 { padding-bottom: ($spacer * .25) !important }
.pb-2 { padding-bottom: ($spacer * .5) !important }
.pb-3 { padding-bottom: ($spacer) !important }
.pb-4 { padding-bottom: ($spacer * 1.5) !important }
.pb-5 { padding-bottom: ($spacer * 2) !important }
.pb-6 { padding-bottom: ($spacer * 3) !important }

.pr-0 { padding-right: 0 !important }
.pr-1 { padding-right: ($spacer * .25) !important }
.pr-2 { padding-right: ($spacer * .5) !important }
.pr-3 { padding-right: ($spacer) !important }
.pr-4 { padding-right: ($spacer * 1.5) !important }
.pr-5 { padding-right: ($spacer * 2) !important }
.pr-6 { padding-right: ($spacer * 3) !important }

.pl-0 { padding-left: 0 !important }
.pl-1 { padding-left: ($spacer * .25) !important }
.pl-2 { padding-left: ($spacer * .5) !important }
.pl-3 { padding-left: ($spacer) !important }
.pl-4 { padding-left: ($spacer * 1.5) !important }
.pl-5 { padding-left: ($spacer * 2) !important }
.pl-6 { padding-left: ($spacer * 3) !important }

.w-100 { width: 100% !important }
.h-100 { height: 100% !important }
.w-80 { width: 80% !important }
.h-80 { height: 80% !important }
.w-50 { width: 50% !important }
.h-50 { height: 50% !important }

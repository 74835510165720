// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material'as mat;
// Plus imports for other components in your app.

// Font Family: Roboto
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
// Icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

@import '~@angular/cdk/overlay-prebuilt.css';

// refer to /node-modules/@angular/material/typography/_typography.scss
$sitepics-web-typography: mat.define-typography-config($font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $headline: mat.define-typography-level(30px, 30px, 500),
  $title: mat.define-typography-level(24px, 24px, 500),
  $subheading-2: mat.define-typography-level(18px, 18px, 400),
  $subheading-1: mat.define-typography-level(18px, 18px, 400),
  $body-2: mat.define-typography-level(16px, 16px, 500),
  $body-1: mat.define-typography-level(16px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
  // Line-height must be unit-less fraction of the font-size.
  $input: mat.define-typography-level(15px, 1.2, 400));
// define topography level parameters
// $font-size,
// $line-height: $font-size,
// $font-weight: 400,
// $font-family: null,
// $letter-spacing: normal

// font-weights by font-type
// thin: 100
// light: 300
// regular: 400
// medium: 500
// bold: 700
// black: 900

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($sitepics-web-typography);

// NOTE Global Styles
// NOTE Import to every component
@import 'assets/bootstrap-helpers.scss';
@import "assets/mixins.scss";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sitepics-web-primary: mat.define-palette($sitepics-palette, 500);
$sitepics-web-accent: mat.define-palette($sitepics-palette-accent, 900);

// NOTE the line below to add more accents that is used by components
// $sitepics-web-primary: mat.define-palette($sitepics-palette, 500, A300, A400, A600);

// The warn palette is optional (defaults to red).
$sitepics-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sitepics-web-theme: mat.define-light-theme((color: (primary: $sitepics-web-primary,
      accent: $sitepics-web-accent,
      warn: $sitepics-web-warn,
    ),
    typography: $sitepics-web-typography, // include custom typography in the theme
  ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($sitepics-web-theme);
@include mat.all-component-themes($sitepics-web-theme);

/*-------------------------- START --------------------------*/


/* You can add global styles to this file, and also import other style files */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Set default icons to be filled
mat-icon {
  font-variation-settings: 'FILL'1;

  // material-symbols-outlined is by default on all mat-icons, below class is to specify that the icon will NOT be filled
  &.material-symbols-outlined-only {
    font-variation-settings:
      'FILL'0,
  }
}

body {
  position: relative;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  overflow: hidden;
}

html,
body {
  height: 100%;
  font-size: $font-16;
  color: $color-label-dark;
  margin: 0;

  .cursor-pointer {
    cursor: pointer;
  }
}

footer {
  margin-top: auto;
}

:root {

  .router-outlet-div {

    .scroll {
      @include scrollbar;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .color-primary,
    .primary {
      color: $color-primary;
    }

    .error {
      color: $color-error;
    }

    // button {
    //   color: $color-white;
    // }

    .text-center {
      text-align: center;
    }

    .text-bold {
      font-weight: bold;
    }

    .page {
      height: 100%;
      overflow-x: hidden;
    }

    mat-label {
      display: block;
      font-size: $font-16;
      font-weight: 500;
      color: $color-label-dark;
      margin-bottom: 0.625rem;
    }

    a {
      font-weight: normal;
    }

    .justify-content-end {
      justify-content: flex-end;
    }

    .justify-content-start {
      justify-content: flex-start;
    }

    .justify-content-center {
      justify-content: center;
      display: flex;
    }

    .justify-content-between {
      justify-content: space-between;
      display: flex;
    }

    // button.btn-primary {
    //   background-color: $color-primary;
    //   color: $color-white;
    // }

    .color-white {
      color: $color-white;
    }

    .color-label-light {
      color: $color-label-light;
    }

    .color-primary {
      color: $color-primary;
    }

    .selected-pin {
      color: $color-selected-pin;
    }

    .align-items-end {
      align-items: flex-end;
    }

    .align-items-start {
      align-items: flex-start;
    }

    .align-items-center {
      align-items: center;
      display: flex;
    }

    .align-items-between {
      align-items: space-between;
      display: flex;
    }
  }

  .mat-simple-snackbar-action {
    button.mat-button {
      background-color: $color-white !important;
    }
  }

  button {
    text-transform: uppercase;
    font-size: 14px;

    &.mat-icon-button {

      // color: $color-light-grey;
      &.favourite,
      &.photo {
        &.active {
          color: $color-primary;
        }
      }

      &.ricoh {
        &.active {
          color: $color-ricoh;
        }
      }

      &.aerial {
        &.active {
          color: $color-aerial;
        }
      }

      &.no-media,
      &.no-geo {
        &.active {
          color: $color-error;
        }
      }

    }

    &.active {
      &.color-black {
        color: $color-black !important;
      }
    }
  }

  .media-actions {
    button {
      &:hover {
        color: $color-primary
      }

      // &.empty-type-icon-btn { color: $color-empty }
      &.image-type-icon-btn {
        color: $color-primary
      }

      // &.video-type-icon-btn { color: $color-video }
      &.ricoh-type-icon-btn {
        color: $color-ricoh
      }

      &.aeriel-type-icon-btn {
        color: $color-aerial
      }

      // &.other-type-icon-btn { color: $color-other }
    }
  }

  .color-favourite,
  .color-photo {
    color: $color-primary;
  }

  .color-ricoh {
    color: $color-ricoh;
  }

  .color-aerial {
    color: $color-aerial;
  }

  // .color-video {
  //   color: $color-video;
  // }

  // .color-other {
  //   color: $color-other;
  // }

  // .color-empty {
  //   color: $color-empty;
  // }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .flex-container {
    display: flex;
    flex-direction: column;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {}


// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {}


// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {}

snack-bar-container.mat-snack-bar-container {
  simple-snack-bar.mat-simple-snackbar {
    .mat-simple-snackbar-action {
      .mat-button-base {
        background-color: $color-white !important;
      }
    }
  }
}

.divider {
  border-bottom: 1px solid #e5e5e5;
}

// .custom-cluster {
//     background-color: red !important; /* Default color for small clusters */
//     border-radius: 50%;
//     color: white;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 30px;
//     height: 30px;
//     font-size: 14px;
//     font-weight: bold;
//     transition: background-color 0.3s ease;
// }

.custom-cluster {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white !important;
  border: 1px solid white !important;
  /* Add blur effect to the border */
  box-shadow: 0 0 4px 1px white;
}

.custom-cluster.primary {
  background-color: #2bb14c;
  /* Primary cluster color */
}

.custom-cluster.secondary {
  background-color: #2a3e50;
  /* Secondary cluster color */
}



/* Bounce animation keyframes */
@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

/* Drop animation keyframes */
@keyframes drop {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Class to apply bounce animation */
.bounce-animation {
  animation: bounce 1s ease;
}

/* Class to apply drop animation */
.drop-animation {
  animation: drop 0.5s ease;
}

/* Fully reset the default Pannellum button styling */
.pnlm-load-button {
  position: absolute !important;
  bottom: 5px;
  right: 5px;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background-color: #202a3e !important;
  color: #fff !important;
  border: 1px solid #fff !important;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out !important;
  opacity: 1;
  /* Start fully visible */
  /* Reset unwanted styles from Pannellum */
  top: unset !important;
  left: unset !important;
  margin: 0 !important;
  /* Remove margin-based centering */
}

/* Hover effect */
.pnlm-load-button:hover {
  background-color: #2bb14c !important;
  border: 1px solid #2bb14c !important;
  color: #fff !important;
}

/* Ensure the button text behaves correctly */
.pnlm-load-button p {
  margin: 0 !important;
  font-size: 14px !important;
  text-align: center !important;
}

/* Fading out the button when the panorama loads */
.pnlm-load-button.hide {
  opacity: 0 !important;
  /* Fade out the button */
  pointer-events: none !important;
  /* Prevent clicking when hidden */
}

.assignBtn {
  cursor: pointer;
  margin-right: 5px;
  height: 30px;
  border: none;
  border-radius: 5px;
  color: $color-light-grey;
  background-color: transparent !important;
  &:hover {
    color: $color-primary;
  }
}

.lassoBtn {
  cursor: pointer;
  margin-left: 5px;
  height: 30px;
  border: none;
  border-radius: 5px;
  color: $color-light-grey;
  background-color: transparent !important;

  &:hover {
    color: $color-primary;
  }

  &.active {
    color: $color-error;
  }
}

.exportLassoBtn {
  cursor: pointer;
  margin-left: 10px;
  height: 30px;
  border: none;
  border-radius: 5px;
  color: $color-error !important;
  background-color: transparent !important;

  &:hover {
    color: $color-error !important;
  }
}


.filter-icon {
  color: $color-light-grey; // Default color
  cursor: pointer;
}

.filters-active {
  color: $color-primary !important; // Set this to your primary color
}


::ng-deep .mat-dialog-container {
  padding: 0px !important;
}

/* Override default .mat-dialog-container styles */
/* Make the modal take full width and height */
/* Force full-screen modal */
.custom-edit-pin-dialog {
  @include modal-scrollbar;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;
  overflow: hidden !important;

  /* Target Angular Material dialog container */
  .mat-dialog-container {
    @include modal-scrollbar;
    width: 100vw !important;
    height: 100vh !important;
    max-width: 100vw !important;
    max-height: 100vh !important;
    display: flex !important;
    flex-direction: column !important;
    background-color: #f8f9fa !important;
    border-radius: 0 !important;
    padding: 0 !important;
    margin: 0 !important;

  }
}

/* Force the parent overlay pane to take full screen */



.cdk-overlay-dark-backdrop {
    background-color: rgba(0, 0, 0, 0.8) !important;
}
.hidden-header-drawer {
	display: none;
}










